import React from "react";
import { useField, ErrorMessage } from "formik";
import MaskedInput from "react-text-mask";

function TextField({ label, ...props }) {

  const [field, meta] = useField(props);

  if (props.mask==undefined) {

    return (
      <div className="mb-2">
      {label ?
        <label htmlFor={field.name} className="form-label">{label}{props.mandatory == "true" ? <span style={{ color: "red" }}> *<span style={{ fontSize: "10px" }}>{props.requis}</span></span> : props.suggested ?  <span style={{ color: "#21598A" }}> *<span style={{ fontSize: "10px" }}>{props.suggest}</span></span> : null }</label> : null }
        <input
          className={`form-control ${meta.touched && meta.error && "is-invalid"
            }`}
          {...field}
          {...props}
        />
        <ErrorMessage component="small" name={field.name} className={`${meta.touched && meta.error && "error_msg"}`} />
      </div>
    );
  }
  else {
    return (
      <div>
        <label htmlFor={field.name}className="form-label">{label}{props.mandatory == "true" ? <span style={{ color: "red" }}> *<span style={{ fontSize: "10px" }}>{props.requis}</span></span> : props.suggested ?  <span style={{ color: "#21598A" }}> *<span style={{ fontSize: "10px" }}>{props.suggest}</span></span> : null }</label>
        <>
          <MaskedInput
            {...field}
            mask={props.mask}
            className={`form-control ${meta.touched && meta.error && "is-invalid"}`}
            {...props}
          />
          <ErrorMessage component="small" name={field.name} className="error_msg" />
        </>
      </div>
    )
  }

}

export default TextField;